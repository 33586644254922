<template>
    <div class="course-wrapper">
        <div class="chart">
            <div class="score-line" :style="{ height: (score / total) * 100 + '%' }"></div>
        </div>
        <div class="course">{{ course }}</div>
        <div class="score">{{ score }}</div>
    </div>
</template>
<script>
export default {
    name: 'Course',
    props: {
        course: String,
        score: Number,
        total: {
            type: Number,
            default: 100,
        },
    },
};
</script>
<style lang="less" scoped>
.course-wrapper {
    .chart {
        width: 20px;
        height: 269px;
        background: #f5f5f5;
        position: relative;
        .score-line {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: #07c160;
        }
    }
    .course {
        font-size: 13px;
        font-family: PingFang SC, serif;
        line-height: 19px;
        color: #666666;
        margin: 9px 0 6px 0;
    }
    .score {
        font-size: 16px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 23px;
        color: #07c160;
    }
}
</style>
