<template>
    <page title="我的成绩" :hasBack="true" textAlign="left">
        <div class="select-wrapper">
            <van-popover v-model:show="nameShow" :actions="selectMsg.nameActions" @select="onSelect">
                <template #reference>
                    <span class="select">{{ currentSelect.name }}</span>
                    <img class="icon" src="@/assets/images/myScore/select.png" alt="" />
                </template>
            </van-popover>
            <van-popover v-model:show="termShow" :actions="selectMsg.termActions" @select="onSelect">
                <template #reference>
                    <span class="select">{{ currentSelect.term }}</span>
                    <img class="icon" src="@/assets/images/myScore/select.png" alt="" />
                </template>
            </van-popover>
            <van-popover v-model:show="courseShow" :actions="selectMsg.courseActions" @select="onSelect">
                <template #reference>
                    <span class="select">{{ currentSelect.course }}</span>
                    <img class="icon" src="@/assets/images/myScore/select.png" alt="" />
                </template>
            </van-popover>
        </div>
        <div class="course-result">
            <course v-for="item in courseList" :key="item.course" :course="item.course" :score="item.score" />
        </div>
        <common-block title="班主任评语" :border="false">
            <div class="comments">
                {{ comments }}
            </div>
        </common-block>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { Popover } from 'vant';
import { reactive, ref } from 'vue';
import Course from './components/course.vue';
import CommonBlock from '@/components/commonBlock/CommonBlock';

export default {
    name: 'schoolNotice',
    components: {
        [Page.name]: Page,
        [Popover.name]: Popover,
        [Course.name]: Course,
        [CommonBlock.name]: CommonBlock,
    },
    setup() {
        const currentSelect = reactive({
            name: '王梦梦',
            term: '请选择学期',
            course: '请选择课程',
        });
        const selectMsg = {
            nameActions: [{ text: '王梦梦' }, { text: '张啦啦' }],
            termActions: [{ text: '上期' }, { text: '下期' }],
            courseActions: [{ text: '上期' }, { text: '下期' }],
        };
        const onSelect = () => {};
        const courseList = [
            { course: '语文', score: 100 },
            { course: '数学', score: 90 },
            { course: '英语', score: 95 },
            // {course: '物理', score: 80},
            // {course: '化学', score: 70},
            // {course: '生物', score: 20},
            // {course: '地理', score: 75},
        ];
        const nameShow = ref(false);
        const termShow = ref(false);
        const courseShow = ref(false);
        const comments = ref('该学生，成绩优秀，乐于助人，尊敬老师，希望学生\n' + '好好学习，更上一楼。');
        return {
            currentSelect,
            selectMsg,
            nameShow,
            termShow,
            courseShow,
            courseList,
            comments,
            onSelect,
        };
    },
};
</script>
<style lang="less" scoped>
.select-wrapper {
    height: 50px;
    background: #f5f5f5;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .select {
        font-size: 14px;
        font-family: PingFang SC, serif;
        color: #333333;
        margin-right: 5px;
    }
    .icon {
        width: 8px;
    }
}
.course-result {
    height: 382px;
    padding: 26px 10px 0;
    background: #ffffff;
    border-radius: 0px 0px 5px 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}
.comments {
    font-size: 13px;
    font-family: PingFang SC, serif;
    line-height: 19px;
    color: #666666;
    padding-bottom: 19px;
    padding-right: 35px;
    box-sizing: border-box;
}
</style>
